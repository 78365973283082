import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Container,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { AuthContext } from "../../providers/auth-provider";

import AiChatDialogContent from "../../components/ai-chat/ai-chat-dialog-content";
import AiChatDialog from "../../components/ai-chat/ai-chat-dialog";

export default () => {
  const { state: authState } = useContext(AuthContext);

  if (!authState?.email) {
    return null;
  }

  if (!window || !window.location || !window.location.pathname || !window.location.search) {
    return <Typography>Please open the LLM chat dialog through a specific course.</Typography>;
  }

  const searchParams = new URLSearchParams(window.location.search);
  const slug = searchParams.get("slug");
  if (!slug) {
    return <Typography>Please open the LLM chat dialog through a specific course.</Typography>;
  }  

  const closeChat = (hadDialogue = false) => {
    window && window.close();
  };

  return (
    <AiChatDialog
      onClose={closeChat}
      courseSlug={slug}
    />
  );
};
